import React, { FunctionComponent } from "react";
import Copyright from "../components/copyright/copyright";
import NavBar from "../components/navbar/navbar";
import Title from "../components/title/title";
import "./notFound.css";

const NotFound: FunctionComponent = () => {
  return (
    <>
      <NavBar black="" />
      <main className={`content__section`}>
        <Title title="You seems to be lost" color="black" />
        <div className="notFound__message">
          <h2 className="notFound__message-code">404</h2>
          <p className="notFound__message-warning">This page doesn't exist</p>
        </div>
      </main>
      <Copyright />
    </>
  );
};

export default NotFound;
