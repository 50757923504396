import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavBar from "./components/navbar/navbar";
import Copyright from "./components/copyright/copyright";

function App() {
  const location = useLocation();

  location.pathname === "/about" ? (document.body.className = "black__background") : document.body.classList.remove("black__background");
  const blackBg: string = location.pathname === "/about" ? " black__background" : "";
  const unset: string = location.pathname === "/about" || location.pathname === "/contact" ? " unset" : "";

  return (
    <>
      <NavBar black={location.pathname === "/about" ? " black__background" : ""} />
      <main className={`content__section${blackBg}${unset}`}>
        <Outlet />
      </main>
      <Copyright />
    </>
  );
}

export default App;
