import gsap from "gsap";
import React, { FunctionComponent, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import EmailSender from "../components/email/emailSender";
import Title from "../components/title/title";
import "./contact.css";

const Contact: FunctionComponent = () => {
  useLayoutEffect(() => {
    const contactGroups = gsap.utils.toArray<HTMLElement>([".mail__form", ".contact__h2"])
    const tl = gsap.timeline({ ease: "expo.out" });
    tl.addLabel("start");
    tl.fromTo("h1", { opacity: 0 }, { opacity: 1, duration: 0.4 });
    tl.addLabel("middle");
    contactGroups.forEach(el => {
      tl.fromTo(el, { opacity: 0 }, { opacity: 1, duration: 1 }, "start+=0.3");
    })
    tl.addLabel("end");
    tl.fromTo(".contact__details", { opacity: 0 }, { opacity: 1, duration: 0.4 }, "middle+=0.5");
  });

  return (
    <>
      <Title title="Contact" color="black" />
      <h2 className="contact__h2">Let's work together</h2>
      <div className="contact__container">
        <EmailSender />
        <div className="contact__details">
          <svg className="contact__details-icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.8443 8.11427C17.0459 8.11427 17.1915 8.17685 17.3149 8.29968C17.4375 8.42307 17.5 8.56858 17.5 8.76997C17.5 8.97176 17.4373 9.11672 17.3148 9.23927C17.1911 9.36295 17.0456 9.42567 16.8443 9.42567H3.92937H2.72227L3.57582 10.2792L9.23874 15.9421C9.35174 16.0551 9.41874 16.1995 9.42535 16.4123C9.43056 16.6018 9.37316 16.7292 9.25443 16.838L9.23841 16.8527L9.22372 16.8687C9.11118 16.9915 8.97762 17.0533 8.78331 17.0533C8.58821 17.0533 8.43617 16.9902 8.29898 16.853L0.671371 9.2254C0.600978 9.15501 0.56291 9.09127 0.542489 9.03508C0.51542 8.95819 0.5 8.87101 0.5 8.76997C0.5 8.66893 0.51542 8.58175 0.542489 8.50486C0.56291 8.44867 0.600979 8.38493 0.67137 8.31454L8.29898 0.686927C8.41507 0.570834 8.55614 0.506691 8.75453 0.500305C8.93074 0.495322 9.07325 0.550327 9.20985 0.686928L9.21753 0.69461L9.22554 0.701951C9.35581 0.821364 9.41939 0.957982 9.42536 1.1424C9.43036 1.31865 9.37536 1.46118 9.23874 1.5978L9.57815 1.93721L9.23874 1.5978L3.57582 7.26072L2.72227 8.11427H3.92937H16.8443Z" />
          </svg>
          <div className="details__container">
            <h3 className="contact__h3">Contact details</h3>
            <div>contact@elouan-savy.com</div>
          </div>
          <div className="details__container">
            <h3 className="contact__h3">Location</h3>
            <div>Long: -0.594 Lat: 44.8378</div>
            <div>Bordeaux, France</div>
          </div>
          <div className="details__container">
            <h3 className="contact__h3">Socials</h3>
            <ul className="socials__link">
              <li className="social__link-li">
                <Link to="https://www.malt.fr/profile/elouansavy">Malt</Link>
              </li>
              <li className="social__link-li">
                <Link to="https://www.linkedin.com/in/elouan-savy-camaret">LinkedIn</Link>
              </li>
              <li className="social__link-li">
                <Link to="https://github.com/ElouanSavy">Github</Link>
              </li>
              {/* <li className="social__link-li">
                <Link to="">Fiverr</Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
