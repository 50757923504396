import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { FunctionComponent, useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Title from "../components/title/title";
import ToolContainer from "../components/toolContainer/toolContainer";
import "./about.css";

gsap.registerPlugin(ScrollTrigger);

const About: FunctionComponent = () => {
  const text = useRef<HTMLDivElement>(null);

  const textAnimTop = (el: HTMLElement) => {
    ScrollTrigger.create({
      trigger: el,
      start: "top 80%",
      end: "bottom 20%",
      onEnter: () => gsap.to(el, { transform: "translate(0, 0)", duration: 0.8, ease: "expo.out" }),
      onEnterBack: () => gsap.to(el, { opacity: 1, duration: 0.6, ease: "expo.out" }),
      onLeave: () => gsap.to(el, { opacity: 0, duration: 0.6, ease: "expo.out" }),
      onLeaveBack: () => gsap.to(el, { opacity: 0, duration: 0.6, ease: "expo.out" }),
    });
  };

  useLayoutEffect(() => {
    const topGroups = gsap.utils.toArray<HTMLElement>([".tool__container-li", ".about__h3-content", ".about__h2-content"]);
    gsap.set(topGroups, { transform: "translate(0, -120%)" });
    topGroups.forEach((e) => {
      textAnimTop(e);
    });
    gsap.fromTo(
      "#h1Pres",
      { opacity: 0 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: "#h1Pres",
          start: "top 80%",
          end: "bottom 50%",
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      ".presentation__text",
      { opacity: 0 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: ".presentation__text",
          start: "top 50%",
          end: "bottom 60%",
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      "#h1Tools",
      { opacity: 1 },
      {
        opacity: 0,
        scrollTrigger: {
          trigger: "#h1Tools",
          start: "top top%",
          end: "bottom top",
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <div className="about__content">
      <Title id="h1Tools" title="skills" color="white" />
      <div className="tools__container">
        <h2 id="test" className="about__h2">
          <div className="about__h2-content">Tools overview</div>
        </h2>
        <div className="tool__list">
          <div className="language__container">
            <h3 className="about__h3">
              <div className="about__h3-content">Languages</div>
            </h3>
            <ToolContainer>
              <div className="tool__container-li">HTML</div>
              <div className="tool__container-li">CSS</div>
              <div className="tool__container-li">SASS/SCSS</div>
              <div className="tool__container-li">Typescript</div>
              <div className="tool__container-li">Vanilla Javascript</div>
              <div className="tool__container-li">Python</div>
            </ToolContainer>
          </div>
          <div className="framework__container">
            <h3 className="about__h3">
              <div className="about__h3-content">FRAMEWORKS/ LIBRARIES/ OTHERS</div>
            </h3>
            <ToolContainer>
              <div className="tool__container-li">React.js</div>
              <div className="tool__container-li">Node.js</div>
              <div className="tool__container-li">ExpressJS</div>
              <div className="tool__container-li">Three.js</div>
              <div className="tool__container-li">Anim.js</div>
              <div className="tool__container-li">GSAP</div>
              <div className="tool__container-li">Chart.js</div>
            </ToolContainer>
          </div>
        </div>
      </div>
      <Title id="h1Pres" title="About me" color="white" />
      <div className="presentation__container">
        <div className="presentation__text" ref={text}>
          I am a young <strong>fullstack capable</strong> developer in training at Epitech Bordeaux, France. I am passionate about the world of development, so{" "}
          <strong>I am looking for full stack missions</strong> to put my skills at the service of <strong>useful and innovative projects</strong>. I am constantly training on new technologies to{" "}
          <strong>continue to progress</strong> and acquire new skills. I'm mainly used to work on <strong>API creation</strong> (NodeJs), <strong>web application </strong>
          (React) or <strong>model integration</strong>.{" "}
          <span>
            Don't hesitate to <Link to="/contact">contact me</Link> to talk about your project if my profile interests you.
          </span>
        </div>
      </div>
    </div>
  );
};

export default About;
