import React, { FunctionComponent, MouseEvent, useState } from "react";
import Project from "../../models/project";
import "./displayProject.css";

type projectProps = {
  projectList: Project[];
};

const ProjectDisplayer: FunctionComponent<projectProps> = ({ projectList }) => {
  const [active, setActive] = useState<number>(0);

  const displayNextProject = () => {
    setActive((active + 1) % projectList.length);
  };

  const displayPreviousProject = () => {
    setActive(active - 1 < 0 ? projectList.length - 1 : active - 1);
  };

  const displayProject = (ev: MouseEvent<HTMLImageElement>) => {
    let id: number = Number(ev.currentTarget.getAttribute('data-id'));
    window.open(`https://${projectList[id].link}`,'_blank');
  }

  return (
    <>
      <div className="project__track">
        <div className="project__img-container">
          <ul className="project__container-ul" style={{ width: projectList.length * 100 + "%" }}>
            {projectList.map((project) => (
              <li className="project__container-li" key={project.id} style={{ transform: `translateX(${-active * 100}%)` }}>
                <img className={`project__container-img${project.id === active ? " active" : ""}`} data-id={project.id} src={project.img} alt={project.title} onClick={displayProject} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="project__info-container">
        <ul className="description__container">
          {projectList.map((project) => (
            <li key={`desc${project.id}`} style={{ transform: `translateY(-${active * 100}%)` }}>
              {project.descEn}
            </li>
          ))}
        </ul>
        <div className="project__nav-container">
          <div className="project__btn-container">
            <div className="project__btn" onClick={displayPreviousProject}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8443 8.11427C17.0459 8.11427 17.1915 8.17685 17.3149 8.29968C17.4375 8.42307 17.5 8.56858 17.5 8.76997C17.5 8.97176 17.4373 9.11672 17.3148 9.23927C17.1911 9.36295 17.0456 9.42567 16.8443 9.42567H3.92937H2.72227L3.57582 10.2792L9.23874 15.9421C9.35174 16.0551 9.41874 16.1995 9.42535 16.4123C9.43056 16.6018 9.37316 16.7292 9.25443 16.838L9.23841 16.8527L9.22372 16.8687C9.11118 16.9915 8.97762 17.0533 8.78331 17.0533C8.58821 17.0533 8.43617 16.9902 8.29898 16.853L0.671371 9.2254C0.600978 9.15501 0.56291 9.09127 0.542489 9.03508C0.51542 8.95819 0.5 8.87101 0.5 8.76997C0.5 8.66893 0.51542 8.58175 0.542489 8.50486C0.56291 8.44867 0.600979 8.38493 0.67137 8.31454L8.29898 0.686927C8.41507 0.570834 8.55614 0.506691 8.75453 0.500305C8.93074 0.495322 9.07325 0.550327 9.20985 0.686928L9.21753 0.69461L9.22554 0.701951C9.35581 0.821364 9.41939 0.957982 9.42536 1.1424C9.43036 1.31865 9.37536 1.46118 9.23874 1.5978L9.57815 1.93721L9.23874 1.5978L3.57582 7.26072L2.72227 8.11427H3.92937H16.8443Z" />
              </svg>
            </div>
            <div className="project__btn" onClick={displayNextProject}>
              <svg className="project__btn-right" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8443 8.11427C17.0459 8.11427 17.1915 8.17685 17.3149 8.29968C17.4375 8.42307 17.5 8.56858 17.5 8.76997C17.5 8.97176 17.4373 9.11672 17.3148 9.23927C17.1911 9.36295 17.0456 9.42567 16.8443 9.42567H3.92937H2.72227L3.57582 10.2792L9.23874 15.9421C9.35174 16.0551 9.41874 16.1995 9.42535 16.4123C9.43056 16.6018 9.37316 16.7292 9.25443 16.838L9.23841 16.8527L9.22372 16.8687C9.11118 16.9915 8.97762 17.0533 8.78331 17.0533C8.58821 17.0533 8.43617 16.9902 8.29898 16.853L0.671371 9.2254C0.600978 9.15501 0.56291 9.09127 0.542489 9.03508C0.51542 8.95819 0.5 8.87101 0.5 8.76997C0.5 8.66893 0.51542 8.58175 0.542489 8.50486C0.56291 8.44867 0.600979 8.38493 0.67137 8.31454L8.29898 0.686927C8.41507 0.570834 8.55614 0.506691 8.75453 0.500305C8.93074 0.495322 9.07325 0.550327 9.20985 0.686928L9.21753 0.69461L9.22554 0.701951C9.35581 0.821364 9.41939 0.957982 9.42536 1.1424C9.43036 1.31865 9.37536 1.46118 9.23874 1.5978L9.57815 1.93721L9.23874 1.5978L3.57582 7.26072L2.72227 8.11427H3.92937H16.8443Z" />
              </svg>
            </div>
          </div>
          <div className="progress__container">
            <div className="progress__bar-track">
              <div className="progress__bar-thumb" style={{ width: `${(active / (projectList.length - 1)) * 100}%` }}></div>
            </div>
            <ul className="project__title-container">
              {projectList.map((project) => (
                <li key={`title${project.id}`} className="project__title-title" style={{ transform: `translateY(-${active * 100}%)` }}>
                  {project.date} - {project.title}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDisplayer;
