import React, { FunctionComponent, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";

type navProps = {
  black: string;
};

const NavBar: FunctionComponent<navProps> = ({ black }) => {
  const location = useLocation();
  const [active, setActive] = useState<string>("");

  const toggleActiveMenu = () => {
    if (width >= 768) {
      setActive("");
    } else active === "" ? setActive(" active") : setActive("");
  };

  const [width, setWidth] = useState<number>(window.innerWidth);

  const windowSizeHandler = (ev: UIEvent) => {
    setWidth(window.innerWidth);
    if (width >= 768) {
      setActive("");
    }
  };

  window.addEventListener("resize", windowSizeHandler);

  return (
    <nav className={"navbar"}>
      <Link className="link home" to="/">
        Elouan SAVY
      </Link>
      <div className="navbar__menu">
        <div className={`menu-icon${active}`} onClick={toggleActiveMenu}>
          <div>
            <span className="menu__span"></span>
            <span className="menu__span"></span>
          </div>
        </div>
        <ul className={`navbar__menu-ul ${active} ${black}`}>
          <li key={0} className="navbar__menu-li">
            <Link className={"link" + (location.pathname === "/" ? " active" : "")} onClick={toggleActiveMenu} to="/">
              Work
            </Link>
          </li>
          <li key={1} className="navbar__menu-li">
            <Link className={"link" + (location.pathname === "/about" ? " active" : "")} onClick={toggleActiveMenu} to="/about">
              About
            </Link>
          </li>
          <li key={2} className="navbar__menu-li">
            <Link className={"link" + (location.pathname === "/contact" ? " active" : "")} onClick={toggleActiveMenu} to="/contact">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
