import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useRef,
  useState
} from "react";
import "./emailSender.css";
import {wait} from "@testing-library/user-event/dist/utils";

type Field = {
  value: string;
  error?: string;
  isValid: boolean;
};

type Form = {
  from_name: Field;
  reply_to: Field;
  message: Field;
};

type Payload = {
  from_name: string;
  reply_to: string;
  message: string;
};

const EmailSender: FunctionComponent = () => {
  const submitButton = useRef<HTMLDivElement | null>(null);
  const errorModal = useRef<HTMLDivElement | null>(null);
  const [modalText, setModalText] = useState<string>("Message sent, see you soon !");
  const [modalClass, setModalClass] = useState<string>("success");
  const [toSend, setToSend] = useState<Form>({
    from_name: { value: "", isValid: false },
    message: { value: "", isValid: false },
    reply_to: { value: "", isValid: false },
  });

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const payload: Payload = { from_name: toSend.from_name.value, message: toSend.message.value, reply_to: toSend.reply_to.value };

    try {
      let response = await fetch("https://portfolio-back-mailer.vercel.app/send-mail", {
        method: "POST",
        body: JSON.stringify(payload),
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (response.status === 200) {
        setToSend({ from_name: { value: "", isValid: false }, message: { value: "", isValid: false }, reply_to: { value: "", isValid: false } });
      }
      setModalClass("success");
      setModalText("Message sent, see you soon !");
    } catch (e) {
      console.log(e);
      setModalClass("error");
      setModalText("Error while sending mail, please try again later");
    }
    if (!submitButton || !submitButton.current)
      return;
    submitButton.current.className = "form__submit-wrapper";
    if (!errorModal || !errorModal.current)
      return;
    errorModal.current.classList.add("show");
    await wait(3000);
    errorModal.current.classList.remove("show");
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newField: Field = { value: e.target.value, isValid: false };
    if (e.target.name !== "reply_to" && newField.value !== "") {
      newField.isValid = true;
    } else {
      if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(newField.value)) newField.isValid = true;
    }

    setToSend({ ...toSend, ...{ [e.target.name]: newField } });
  };

  const isComplete = (field: Field): string => {
    if (field.isValid) return "completed";
    return "";
  };

  const isValidForm = (): boolean => {
    return toSend.from_name.isValid && toSend.reply_to.isValid && toSend.message.isValid;
  };

  const submitHandler = () => {
    if (!submitButton || !submitButton.current)
      return;
    submitButton.current.className = "form__submit-wrapper animated";
  }

  return (
    <>
      <form className="mail__form" onSubmit={onSubmit}>
        <div className="form__input-container">
          <label className={isComplete(toSend.from_name)} htmlFor="from_name">
            What's your name?
          </label>
          <input id="from_name" className="custom__input" type="text" name="from_name" placeholder="John Doe" value={toSend.from_name.value} onChange={handleChange} />
        </div>
        <div className="form__input-container">
          <label className={isComplete(toSend.reply_to)} htmlFor="reply_to">
            What's your email?
          </label>
          <input id="reply_to" className="custom__input" type="text" name="reply_to" placeholder="john.doe@email.com" value={toSend.reply_to.value} onChange={handleChange} />
        </div>
        <div className="form__input-container">
          <label className={isComplete(toSend.message)} htmlFor="message">
            What is your message?
          </label>
          <textarea id="message" className="custom__input" name="message" placeholder="Hello Elouan I would need to... " rows={6} value={toSend.message.value} onChange={handleChange} />
        </div>
        {isValidForm() ? (
          <div className="form__submit-wrapper" ref={submitButton}>
            <button className="form__submit" type="submit" onClick={submitHandler}>
              <svg className="form__submit-icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8443 8.11427C17.0459 8.11427 17.1915 8.17685 17.3149 8.29968C17.4375 8.42307 17.5 8.56858 17.5 8.76997C17.5 8.97176 17.4373 9.11672 17.3148 9.23927C17.1911 9.36295 17.0456 9.42567 16.8443 9.42567H3.92937H2.72227L3.57582 10.2792L9.23874 15.9421C9.35174 16.0551 9.41874 16.1995 9.42535 16.4123C9.43056 16.6018 9.37316 16.7292 9.25443 16.838L9.23841 16.8527L9.22372 16.8687C9.11118 16.9915 8.97762 17.0533 8.78331 17.0533C8.58821 17.0533 8.43617 16.9902 8.29898 16.853L0.671371 9.2254C0.600978 9.15501 0.56291 9.09127 0.542489 9.03508C0.51542 8.95819 0.5 8.87101 0.5 8.76997C0.5 8.66893 0.51542 8.58175 0.542489 8.50486C0.56291 8.44867 0.600979 8.38493 0.67137 8.31454L8.29898 0.686927C8.41507 0.570834 8.55614 0.506691 8.75453 0.500305C8.93074 0.495322 9.07325 0.550327 9.20985 0.686928L9.21753 0.69461L9.22554 0.701951C9.35581 0.821364 9.41939 0.957982 9.42536 1.1424C9.43036 1.31865 9.37536 1.46118 9.23874 1.5978L9.57815 1.93721L9.23874 1.5978L3.57582 7.26072L2.72227 8.11427H3.92937H16.8443Z" />
              </svg>
            </button>
          </div>
        ) : null}
        <div ref={errorModal} className={"form__modal " + modalClass}>{modalText}</div>
      </form>
    </>
  );
};

export default EmailSender;
