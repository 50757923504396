import React, { Children, FunctionComponent, ReactNode } from "react";

type toolContainerProps = {
    children: ReactNode
}

const ToolContainer: FunctionComponent<toolContainerProps> = ({children}) => {
    const childrenArr = Children.toArray(children);
    return (
        <ul className="tool__container-ul">
            {Children.map(childrenArr, ((child, index) => {
                if (!React.isValidElement(child)) {
                    return <></>;
                }
                return <li className="child__container-li" key={index}>{child}</li>
            }))
            }
        </ul>
    )
}

export default ToolContainer;
