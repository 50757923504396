import React, { FunctionComponent } from "react";
import "./title.css";

type titleProps = {
  title: string;
  color: string;
  id?: string;
};

const Title: FunctionComponent<titleProps> = ({ title, color, id }) => {
  return (
    <h1 id={id} className={`h1 ${color}`}>
      {title}
    </h1>
  );
};

export default Title;
