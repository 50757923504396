import React, { FunctionComponent, useState } from "react";
import "./copyright.css"

const Copyright: FunctionComponent = () => {
    const year = useState<string>(new Date().getFullYear().toString());

  return <footer className="copyright">{`All rights reserved, Elouan Savy ©${year[0]}`}</footer>;
};

export default Copyright;
