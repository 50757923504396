import aow from "../images/Awarreofworld.jpg";
import chalice from "../images/chalice.jpg";
import gareth from "../images/garethcoker.png";
import urss from "../images/urss.jpg";
import zephyr from "../images/zephyr.jpg";
import Project from "./project";

const PROJECTS: Project[] = [
  {
    id: 0,
    title: "Zephyr",
    desc: "Ce projet personnel a été développé pendant l’été 2023 en duo avec Hippolyte David. Il résulte d’une envie de mener à bien un projet web plus conséquent dans son intégralité.",
    descEn:
      "This personal project was developed during the 2023 summer in duo with Hippolyte David. It is the result of a desire to carry out a more significant web project in its entirety.",
    img: zephyr,
    link: "www.zephyr-weather.fr/",
    date: "2023",
  },

  {
    id: 1,
    title: "Gareth-Coker",
    desc: "Ce projet est développé dans le but de créer une experience audio interactive. Nous avons ici choisi de mettre en avant le travail de Gareth Coker. Les technologies utilisées pour ce projet sont Three.js, Locomotive scroll, Sass et enfin Anim.js",
    descEn:
      "This project is developed to create an interactive audio experience. We have chosen here to highlight the work of Gareth Coker. The technologies used for this project are Three.js, Locomotive scroll, Sass and finally Anim.js",
    img: gareth,
    link: "www.gareth.elouan-savy.com/",
    date: "2021",
  },

  {
    id: 2,
    title: "Deliver the Chalice",
    desc: "Ce projet est un jeux entièrement réalisé en JavaScript. Il m'a permis de découvrir les notions de collisions et de gestion des sprites. Ce projet s'inspire de l'univers de CupHead.",
    descEn: "This project is a game fully realized in JavaScript. It allowed me to discover the notions of collisions and sprite management. This project is inspired by the CupHead universe.",
    img: chalice,
    link: "www.chalice.elouan-savy.com/",
    date: "2021",
  },

  {
    id: 3,
    title: "U — RSS",
    desc: "Ce projet est un projet de groupe de fin de troisième semestre. Il a été réalisé en cinq jours. L'objectif de ce projet était la réalisation d'un agrégateur de flux RSS comprenant plusieurs fonctionnalités imposées.",
    descEn:
      "This project is a group project at the end of the third semester. It was realized in five days. The objective of this project was the realization of an RSS feed aggregator including several imposed functionalities.",
    img: urss,
    link: "www.urss.elouan-savy.com",
    date: "2021",
  },

  {
    id: 4,
    title: "Aware off world",
    desc: "Ce projet est un projet de groupe réalisé au cours de ma première année de DUT. Il est à l’origine une data visualisation sur le thème des déchets dans le monde. Par manque de temps le site n’est développé que pour les desktop, l'expérience n'est pas garantie pour les mobiles.",
    descEn:
      "This project is a group project realized in my first year in the Limoges university. It is originally a data visualization on the theme of waste in the world. Due to lack of time the site is only developed for desktop, the experience is not guaranteed for mobiles.",
    img: aow,
    link: "www.aware-of-world.elouan-savy.com/",
    date: "2020",
  },
];

export default PROJECTS;
