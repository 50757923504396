import gsap from "gsap";
import React, { FunctionComponent, useEffect, useLayoutEffect, useState } from "react";
import ProjectDisplayer from "../components/displayProject/displayProject";
import Title from "../components/title/title";
import Project from "../models/project";
import PROJECTS from "../models/projectList";

const Home: FunctionComponent = () => {
  const [projectList, setProjectList] = useState<Project[]>([]);

  useEffect(() => {
    setProjectList(PROJECTS);
  }, []);

  useLayoutEffect(() => {
    const tl = gsap.timeline({ ease: "expo.out" });
    tl.addLabel("start");
    tl.fromTo("h1", { opacity: 0 }, { opacity: 1, duration: 0.4 });
    tl.addLabel("middle");
    tl.fromTo(".project__info-container", { opacity: 0 }, { opacity: 1, duration: 0.4 }, "start+=0.5");
    tl.addLabel("end");
    tl.fromTo(".project__track", { opacity: 0 }, { opacity: 1, duration: 1 }, "middle+=0.3");
  });

  return (
    <>
      <Title title="Work" color="black" />
      <ProjectDisplayer projectList={projectList} />
    </>
  );
};

export default Home;
